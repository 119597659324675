/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";
@import "@ng-select/ng-select/themes/material.theme.css";
@import '~mapbox-gl/dist/mapbox-gl.css';

/*
 * App CSS
 * ----------------------------------------------------------------------------
 * Imports a file that can contain Sass/CSS that should be used throughout
 * the entire app.
 */

@import "./app/app.scss";

@import "node_modules/swiper/swiper.scss";
@import "@ionic/angular/css/ionic-swiper";

// placed here instead of tutorial.scss due to slide els not getting ng scoping attribute
.swiper-slide {
  display: block;
}
.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.ionic-selectable {
  max-width: 70% !important;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.double-column {
  display: flex;
  flex-direction: column;
  flex: 2
}

.five-column {
  display: flex;
  flex-direction: column;
  flex: 5
}

.center-ng-select-clients {
  width: 95%;
  margin: 0 auto;
}


.flexbox-container {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
}

.flexbox-container > div {
  width: 50%;
}

.flexbox-container > div:first-child {
  margin-right: 20px;
}



app-budgets-list, app-projects-list, app-jobs-list {

  ion-card-title span {
    cursor: pointer;
  }

  ion-card-title {
    font-size: 15px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ion-card-header {
    padding: 0 0 0 20px;
  }
}
